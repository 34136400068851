import React, { useState } from 'react'
import { Container, Dropdown } from 'react-bootstrap';
import BannerInside from '../components/Bannerinside/Bannerinside'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './careers.scss';

const Careers = () => {
  const testimony = [
    {
      id: 1,
      title: "Mr. Rhythm Gupta",
      subtitle: "Co-Founder Easy Eat",
      content: "As much as I cherish my AIR 27 in IIT-JEE and my four years at IIT Delhi, I fondly reminisce my four years stint at Edfora. It was indeed both fascinating and challenging. We were forerunners in solving the jigsaw of learning from the perspective of all the stakeholders in the learning ecosystem. Driven by passion and quest for impact, we pioneered in architecting truly transformative learning platforms conceived from the first principles.",
      nextContent: "Edfora turned out to be a perfect laboratory for flexing my innate entrepreneurial yearnings. It became a launchpad for me toward the fulfillment of my dream to take a plunge into entrepreneurship. I recommend Edfora to all those people who are nurturing a dream to make it really big in life someday..."
    },
    {
      id: 2,
      title: "Mr. Prabhat Kumar",
      subtitle: "Co-Founder SiteRecon",
      content: "After graduating from IIT Delhi, I was on the lookout for the right organization and after a couple of short stints at a couple of companies in Japan, I landed up at Edfora in the role of a Product Manager and soon grew to become AVP building products around deep analytical algorithms. My 4 years at Edfora gradually prepared me to take on a much fuller role at my current startup as a Co-Founder. I cherish my learnings at Edfora and recall instances that guide me in dealing with similar situations as they emerge.",
      nextContent: "While I built great stuff at Edfora, I also graduated with the essential attributes of an accomplished entrepreneur ready to scale heights. For me, Edfora was like a runway that allowed me to take off. I would recommend Edfora to all budding entrepreneurs who have the resolve to set up their own startup."
    },
    {
      id: 3,
      title: "Mr. Akshay Chauhan",
      subtitle: " CGO, Successive Technologies",
      content: "Prior to joining Edfora in 2017, I had experimented with a food startup in an online marketplace model which could not take off and had to be wound up. Edfora allowed me a diverse portfolio, starting with sales and moving on to operations, eventually playing a larger role in my capacity as Chief of Staff. It provided me with ample opportunity to hone my entrepreneurial skills by entrusting challenging tasks and responsibilities. I worked closely with the management in general, and the CEO in particular, leading key initiatives for the organization. Edfora is a great organization to enlarge your role and at Edfora opportunities galore for those who have the appetite. I strongly recommend Edfora to all those who aspire to have a steep learning curve and a quick career progression."
    },
    {
      id: 4,
      title: "Mr. Purnendra Kishore",
      subtitle: "Co-Founder ex-CMD Edfora",
      content: "My corporate journey of 28 years began with the core sector with L&T after completing my B-Tech from IIT BHU. I subsequently pursued MBA from IIM Calcutta and took up roles in operations with HP, SAP, and JKT before moving to the Education sector in 2008 and eventually joining in 2014 to build the Ed-Tech arm Edfora. This venture-building experience turned out to become the most fulfilling one. Building teams, products, policies, and culture for 8 good years enlarged my vision and perspective. We achieved key milestones along the way laying a rock-solid foundation for Edfora's Global play. Edfora will remain ever dear to me and I would encourage Ed-Tech enthusiasts to experience the great stuff they are doing."
    },
  ];

  const jobsall = [
    { id: 1, postDate: '23-Nov-2022', post: 'Account Manager Agency Sales', experience: '1 - 4 Years', location: 'New Delhi', fullTime: 'Full Time' },
    { id: 2, postDate: '23-Nov-2022', post: 'Ad Operations Analyst', experience: '1 - 4 years', location: 'Bangalore', fullTime: 'Full Time' },
    { id: 3, postDate: '23-Nov-2022', post: 'IT Services Consultant', experience: '3 - 6 years', location: 'New Delhi', fullTime: 'Full Time' },
    { id: 4, postDate: '23-Nov-2022', post: 'Engagement Manager', experience: '2 - 4 years', location: 'Noida', fullTime: 'Full Time' },
    { id: 5, postDate: '23-Nov-2022', post: 'Frontend Developer', experience: '3 - 4 years', location: 'Greater Noida', fullTime: 'Full Time' },
    { id: 6, postDate: '23-Nov-2022', post: 'Backend Developer', experience: '5 - 10 years', location: 'New Delhi', fullTime: 'Full Time' },
    { id: 7, postDate: '23-Nov-2022', post: 'UI/UX Designer', experience: '1 - 4 years', location: 'New Delhi', fullTime: 'Full Time' },
    { id: 8, postDate: '23-Nov-2022', post: 'Quality Analyst', experience: '1 - 2 years', location: 'New Delhi', fullTime: 'Full Time' },
    { id: 9, postDate: '24-Nov-2022', post: 'Quality Analyst', experience: '1 - 2 years', location: 'New Delhi', fullTime: 'Full Time' },
  ];

  var testimonials = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: false,
    loop: false,
    // centerPadding:'10px',
    // fade: true,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        }
      },
    ]
  };

  var lifeAtEdfora = {
    dots: true,
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    loop: false,
  };

  const [post, setName] = useState('jobsall');

  const [foundUsers, setFoundUsers] = useState(jobsall);
  const filter = (e: { target: { value: any; }; }) => {
    const keyword = e.target.value;

    if (keyword !== '') {
      const results = jobsall.filter((jobsall) => {
        return jobsall.post.toLowerCase().startsWith(keyword.toLowerCase());
        // Use the toLowerCase() method to make it case-insensitive
      });
      setFoundUsers(results);
    } else {
      setFoundUsers(jobsall);
      // If the text field is empty, show all users
    }

    setName(keyword);
  }

  function setModalShow(arg0: boolean) {
    throw new Error('Function not implemented.');
  }


  // const handleClickScroll = () => {
  //   const element = document.getElementById('openings');
  //   if(element){
  //     element.scrollIntoView({ behavior: 'smooth' });
  //   }
  // };
 

  return (
    <div className="aboutUs">
    <BannerInside PageTitle="Give a boost to your Career"></BannerInside>
    <a className='currentOpenings' href="/internshipprogramme">Internship Openings</a>
    <section className='innerPageSec contentArea'>
      <Container>
        <div className="boxTop">
          <h2 className="secTitle position-relative text-center">Why Join Us</h2>
          <div className="careersMain">
            <div>
              <p>Edfora offers a super accelerated career progression to people who want to create extraordinary products with a truly global impact - Steep learning curve, Opportunity to create deep impact, Latest Tech stack, Culture of innovation, Growth mindset and opportunity to create wealth.<br /><br />
              The working environment at Edfora is intense, immersive and challenging. You will be working on high octane initiatives and indulging in deep tech riddles. You will get to constantly learn from stalwarts in industry and academia. You will draw a constant motivation from frequent opportunities of recognition and rewards.<br /><br />
              <b>"Culture at Work"</b><br />
              Edforians breathe innovation, display dynamism, react well to change, believe in teamwork and are able to multitask. They exhibit exemplary levels of ownership and learnability quotient as they are habitually entrepreneurial. In short we are a gang of "Hipsters, Hackers and Hustlers".</p>
            </div>
            <div className='rgtCareers'>
              <span><img src='/images/highWork.png' alt='High Impact Work' />High Impact Work</span>
              <span><img src='/images/growthPotential.png' alt='Growth Potential' />Growth Potential</span>
              <span><img src='/images/passionateTeam.png' alt='Passionate Team' />Passionate Team</span>
            </div>
          </div>
        </div>
      </Container>
    </section>
    <section className='lifeAtEdforaSec'>
      <Container>
        <div className="boxTop insideNoBox">
          <h2 className="secTitle position-relative text-center">Life at Edfora</h2>
          <p className='text-center'>Take a sneak peek at our Edfora family! We celebrate achievements in style and have a fuller experience at work and beyond….</p>
          <div className="edforaLifeSec">
            <Slider {...lifeAtEdfora}>
              <div className="edforaLifeSecItem"><img src='/images/edforianLife.jpg' alt='Edforian' /></div>
              <div className="edforaLifeSecItem"><img src='/images/edforianLife1.jpg' alt='Edforian' /></div>
              <div className="edforaLifeSecItem"><img src='/images/edforianLife2.jpg' alt='Edforian' /></div>
              <div className="edforaLifeSecItem"><img src='/images/edforianLife3.jpg' alt='Edforian' /></div>
              <div className="edforaLifeSecItem"><img src='/images/edforianLife4.jpg' alt='Edforian' /></div>
            </Slider>
            <div className="edforaLifeBlock d-none">
              <div className="firstBox">
                <div className="firstTop"><img src="/images/gallery-first-top.jpg" alt="Gallery" /></div>
                <div className="firstbottom"><img src="/images/gallery-first-bottom.jpg" alt="Gallery" /></div>
              </div>
              <div className="middleBox"><img src="/images/gallery-middle.jpg" alt="Gallery" /></div>
              <div className="thirdBox">
                <div className="thirdTop"><img src="/images/gallery-third-top.jpg" alt="Gallery" /></div>
                <div className="thirdBottom"><img src="/images/gallery-third-bottom.jpg" alt="Gallery" /></div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
    <section className='testimonialSliderView greyBox'>
      <Container className='contentArea'>
        <div className="insideNoBox">
          <h2 className="secTitle position-relative text-center">Voice of Edforians</h2>
          <p>Look what are Alumini have to say…</p>
        </div>
        <Slider {...testimonials}>
          {testimony.map(item => (
            <div className='testimonialsView' key={item.id}>
              <img src='/images/commasIcon.svg' alt='Double Quotes' />
              <h3>{item.title}</h3>
              <p>{item.subtitle}</p>
              <span>{item.content}</span>
              <span className='pt-3'>{item.nextContent}</span>
            </div>
          ))}
        </Slider>
      </Container>
    </section>
    <Container id='openings' className='d-none'>
      <div className="insideNoBox">
        <h2 className="secTitle position-relative text-center">We're looking for disruptors,
        <span className='secSubTitle text-center'>innovators and change makers!<small>Are you?</small></span></h2>
      </div>
      <div className="searchList">
        <div><input type="text" value={post} onChange={filter} placeholder='Job title' /></div>
        <div>
          <Dropdown className='etype'>
            <Dropdown.Toggle variant="" id="dropdown-basic">
              All Departments
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item href="#">General</Dropdown.Item>
              <Dropdown.Item href="#">Business</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <div className="totalOpenings">
        Total Openings: <b>{foundUsers.length}</b>
      </div>
      <ul className="jobListings">
      {foundUsers && foundUsers.length > 0 ? (
          foundUsers.map((jobsall) => (
          <li className='' key={jobsall.id}>
            <i>{jobsall.postDate}</i>
            <b>{jobsall.post}</b>
            <em>Experience:  <strong>{jobsall.experience}</strong></em>
            <p>{jobsall.location} | {jobsall.fullTime}</p>
            <button  onClick={() => setModalShow(true)} >Apply Now</button>
          </li>
         ))
         ):(
           <li className="noResult">No results found!</li>
         )}
      </ul>   
    </Container>
  </div>
  )
}
export default Careers;