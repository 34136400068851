import React from 'react';
import { Container } from 'react-bootstrap';
import BannerInside from '../components/Bannerinside/Bannerinside';

function PrivacyPolicy(){
    return(
        <div className='innerPageBgBlock'>
            <BannerInside PageTitle="Privacy Policy"></BannerInside>
            <section className='innerPageDetails'>
                <Container>
                    <p>Edfora, a Private Company limited by shares is engaged in providing a technology platform which will be a medium for selling online training cum educational content to  various students through the payment gateway prescribed by the website which will provide facilities to the various students to practice what he/she has learnt, have an analysis of his/her understanding and undergo testing to evaluate his/her standing vis - a - vis other aspirants in the similar field.
                    <br/><br/>
                    This whole arrangement  can be accessed by paying a nominal fee for subscribing or purchasing the online educational content as per their choice. The website shall provide access to the purchased content under the cover of “Privacy Policy” which aims to provide confidence to our customers and protect their privacy in all respects.
                    This privacy policy has been compiled to better serve those who are concerned with how their sensitive personal data or information is being used. Please read our privacy  policy carefully to get a clear understanding of how we collect, use, protect or otherwise handle your Sensitive Personal Information in accordance with our website.
                    <br/><br/>
                    This Privacy policy describes our policy for collection, use, disclosure, retention, and protection of your personal information from fraud, misuse. It applies to our site, all its pages and to any of our application, service, or tool (collectively "Services") where this Privacy Policy is referenced, regardless of how you access or use them, including through mobile or any other devices such as an ipad.
                    <br/><br/>
                    Protecting data is a matter of trust and your privacy is important to us. We shall therefore only use your name and other information, which relates to you, in the manner set forth in this Privacy Policy.
                    <br/><br/>
                    We will only collect information where it is necessary for us to do so and we will only collect such information, which is relevant to our dealings with you. We will only keep your information for as long as law either requires us to or as is relevant for the purposes for which it was collected. You can visit the website and browse without having to provide your personal details. During your visit to the website you remain anonymous and at no time can we identify you.
                    <br/><br/>
                    If you have any suggestions/comments, we shall be pleased to hear from you. By using our Services and/or sharing your personal information with our website in any way whatsoever, you are accepting the terms of this Privacy Policy and our User agreement, 
                        and you are consenting to our collection, use, disclosure, retention, and protection of your personal information as described in this Privacy Policy. If you do not provide the information we require, we may not be able to provide all of our services to you. 
                        If you reside outside India, you agree and understand that you are agreeing to the terms of this policy and our terms of use, which is subject to Indian Law and Indian Jurisdiction.</p>

                    <h3 className='titleHead'>Personal information</h3>
                    <p>"Personal information" is information that is associated with a specific person and his way of using or surfing websites and which data can be used to identify the user or his behavior. <br/>
                    All user information is person specific and the information as supplied by the users enables us to provide you the most user-friendly and customized experience for serving the customer in a better way.<br/> 
                    Please be advised that we do not consider personal information to include such information that has been made anonymous or aggregated so that it can no longer be used to identify a specific person,<br/>
                    whether in combination with other information or otherwise.</p>

                    <h3 className='titleHead'>Changes to this Privacy Policy</h3>
                    <p>Edfora also believes that the internet is an ever-evolving medium. We may periodically review from time to time and change our privacy policy to incorporate such future changes as may be considered appropriate. Our use of any information we gather will always be consistent with the policy under which the information was collected, regardless of what the new policy may be. We may amend this Privacy Policy at any time by updating the amended terms on this site. All amended terms automatically take effect the day it is posted.</p>

                    <h3 className='titleHead'>Collection of Personal Information</h3>
                    <p>We collect process and retain information from you when you enter any information on our site particularly as:</p>
                    <ol type='1'>
                        <li>You respond to a survey or marketing communication,</li>
                        <li>You surf the website, or</li>
                        <li>Use certain other features of our website</li>
                        <li>Participate in community discussions, chats, or dispute resolution, or when you otherwise correspond with us regarding our services.</li>
                    </ol>
                    <h5 className='subText'>The personal information we collect includes the following information</h5>
                    <ol type='1'>
                        <li>Additional information we are mandated or required or authorized by applicable laws to collect and process in order to authenticate or identify you or to verify the information we have collected;</li>
                        <li>Personal information we collect automatically when you use our services or register for an account with us; We also collect information about your interaction with our services, your advertising preferences, and your communications with us. This is the information we receive from the devices (including mobile devices) you use when you choose our services, register for an account with us, provide us information on a web form, update or add information to your account, participate in community discussions, chats, or dispute resolution, or when you otherwise correspond with us regarding our services. This information comprises the following: Device ID or unique identifier, device type, ID for advertising, and unique device token; Geo-location information, including location information from your mobile device. Please be informed that most mobile devices allow you to control or disable the use of location services by any application on your mobile device in the device's settings' menu; Computer and connection information such as statistics on your page views, traffic to and from the sites, referral URL, ad data, your IP address, your browsing history and your web log information.</li>
                    </ol>
                    <h3 className='titleHead'>Personal information we collect using cookies, web beacons, 
                    and similar technologies</h3>
                    <p><strong>Cookies:</strong> are small files that a site or its service provider transfers to your computer's hard drive through your web browser (if you allow) that enables the site's or service provider's systems to recognize your browser and capture and remember certain information. For instance, we use cookies to help us remember and process your information. They are also used to help us understand your preferences based on previous or current site activity, which enables us to provide you with improved and customer specific services. We also use cookies to help us compile aggregate data about site traffic and site interaction so that we can offer better site experiences and tools in the future.</p>
                    <h5 className='subText'>We use Cookies to:</h5>
                    <ol type='1'>
                        <li>Understand and save user's preferences for future visits.</li>
                        <li>Compile aggregate data about site traffic and site interactions in order to offer better site experiences and tools in the future.</li>
                        <li>We may also use trusted third party services that track this information on our behalf. Use third-party services to track this information for us.</li>
                        <li>To restrict usage of software on defined number of machines to avoid unauthorized access of software</li>
                    </ol>
                    <br/>
                    <p>We use "cookies", or similar electronic tools to collect information to assign each visitor a unique random number as a User Identification (User ID) to understand the user's individual interests using the Identified Computer to improve the effectiveness & usability of our site for our users. The personal information a cookie can contain is information supplied by the user. A cookie cannot read data off the user's hard drive. Our advertisers may also assign our own cookies to the user's browser (if the user clicks on our ad banners), which is outside the control of Edfora. Edfora’s web servers automatically collect limited information about a user's computer connection to the internet, including the user's IP address, when the user visits our site. (User's IP address is a number that lets computers get attached to the internet to know where to send data to the user (such as the web pages viewed by the user). The user's IP address does not identify the user personally. Edfora uses this information to deliver its web pages to users upon request, to tailor its site to the interests of its users, to measure traffic within its site and let advertisers know the geographic locations from where Edfora’s visitors come. You can choose to have your computer warn you each time a cookie is being sent, or you can choose to turn off all cookies. You do this through your browser (like internet explorer) settings. Each browser is a little different, so look at your browser's Help Menu to learn the correct way to modify your cookies. If you disable cookies off, some features will be disabled as customization to suit your habits will not work. However, you will still be able to surf our website.</p>
                    <h3 className='titleHead'>Personal information from other sources</h3>
                    <p>
                        We may supplement the information we collect with information from third parties and add it to your account information. For example, we may collect and use demographic information that is publically available, additional contact information, credit check information, and information from credit bureaus, as allowed by applicable Indian Laws. We may allow you to share information with social media sites, or use social media sites to create your account or to connect your account with the respective social media site.<br/><br/>
                        Those social media sites may give us automatic access to certain personal information retained by them about you (e.g., content viewed by you, content liked by you, and information about the advertisements you have been shown or may have clicked on, etc.). If you provide us with access to any site with video content, then you agree that we may share your video viewing with, or obtain information about your video viewing from, third-party social media sites for at least two years or until you withdraw consent. You control the personal information you allow us to have access to through the privacy settings on the applicable social media site and the permissions you give us when you grant us access to your personal information retained by the respective social media site about you.<br/><br/>
                        By associating an account managed by a social media site with your account and authorizing us to have access to this information, you agree that we may collect, use and retain the information provided by these social media sites in accordance with this privacy policy If you give us personal information about someone else, you must do so only with their explicit and prior consent. You are responsible to inform them (if required) about our privacy policy and terms of use.
                    </p>
                    <h3 className='titleHead'>Log Data</h3>
                    <p>We want to inform you that whenever you use our Service, in case of an error in the application we collect data and information (through third party products) on your phone called Log Data. This Log Data may include information such as your device Internet Protocol (“IP”) address, device name, operating system version, the configuration of the app when utilizing our Service, the time and date of your use of the Service, and other statistics.</p>
                    <h3 className='titleHead'>Protection of Information</h3>
                    <p>We work to protect the security of your information by implementing several measures viz. email and mobile authentication, two stage validations for use of software on a new device, using industry standard software and database. Company keeps evaluating the security needs and evaluates new options for implementation in future viz. transmission by using Secure Sockets Layer (SSL) software, which encrypts information you input etc. The physical, electronic and procedural safeguards in connection with the collection, storage and disclosure of personally identifiable customer information are duly implemented by us. Our security procedures mean that we may occasionally request proof of identity before we disclose personal information to you.</p>
                    <h3 className='titleHead'>Third Party Disclosure</h3>
                    <p>We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information unless we provide you with advance notice. However, joint promotions are possible to share the related information with a view to offer a wider portfolio, experience, and knowledge to its customers. This does not include website hosting partners and other parties who assist us in operating our website, conducting our business, or servicing you, provided that such parties agree to keep this information confidential. We may also release your information when we believe release is appropriate to comply with the law, enforce our site policies, or protect ours or others' rights, property, or safety. However, we may provide/share non-personally identifiable visitor information to other parties for marketing, advertising or other uses at our discretion.</p>
                    <h3 className='titleHead'>Third Party Links</h3>
                    <p>We may occasionally include links to other websites. Such websites are governed by their respective privacy policies, which are beyond our control. Once the user leaves our servers (the user can tell where he/she is by checking the URL in the location bar on the user's browser), use or otherwise processing of any such information provided by the user is governed by the privacy policy of the operator of the site which the user is visiting. That policy may differ from our own. If the user can't find the privacy policy of any of these sites via a link from the site's homepage, the user may contact the site directly for more information.</p>
                    <h3 className='titleHead'>Third Party Advertisers</h3>
                    <p>When Edfora presents information to its online advertisers - to help them understand our audience and confirm the value of advertising on our website - it is usually in the form of aggregated statistics on traffic to various pages within our site. When you register with Edfora, we contact you from time to time about updating your content to provide features which we believe may benefit you. We wish to inform you that we use third-party advertisers to serve ads when you visit our web sites. These advertisers may use information (excluding your name, address, email address or telephone number) about your visits to this and other websites in order to provide advertisements about goods and services which might interest you.</p>
                    <h3 className='titleHead'>Google</h3>
                    <p>We use Google AdSense or Analytics and other third party services/products including Google's advertising requirements which can be viewed from Google's Advertising Principles. They are in place to provide a positive experience for users. Google, a third party vendor, uses cookies to serve ads on our site. Google's use of the DART cookie enables it to serve ads to our users based on their visit to our site and other sites on the Internet. Users may opt out of the use of the DART cookie by visiting the Google ad and content network privacy policy. We may implement the following advertising features:</p>
                    <ol type='1'>
                        <li>Remarketing with Google AdSense</li>
                        <li>Google Display Network Impression Reporting</li>
                        <li>Demographics and Interests Reporting</li>
                        <li>Double-click Platform Integration</li>
                    </ol>
                    <p>We, along with third-party vendors, such as Google use first-party cookies (such as the Google Analytics cookies) and third-party cookies (such as the DoubleClick cookie) or other third-party identifiers together to compile data regarding user interactions with ad impressions, and other ad service functions as they relate to our website.</p>
                    <h3 className='titleHead'>Opting out</h3>
                    <p>Users can set preferences for how Google advertises to you using the Google Ad Settings page. Alternatively, you can opt out by visiting the Network Advertising initiative opt out page or permanently using the Google Analytics Opt Out Browser add on.</p>
                    <h3 className='titleHead'>Use of Personal Information</h3>
                    <p>We use the personal information we collect from you in our endeavors to serve you in a better way and more particularly:</p>
                    <ol type='1'>
                        <li>Improvement of User Experience;</li>
                        <li>Personalisation of user experience on our sites;</li>
                        <li>Contacting you about your account and our Services,</li>
                        <li>Customer Services;</li>
                        <li>Personalized Advertising & Marketing;</li>
                        <li>For detection, prevention, mitigation and investigation of fraudulent or illegal activities.</li>
                    </ol>
                    <br/>
                    <p>We retain your personal information as long as it is necessary and expedient for our operations. In addition, we may retain personal information from closed accounts to comply with national laws, prevent fraud, collect any fees owed, resolve disputes, troubleshoot problems, assist with any investigation, enforce our terms of use and take other actions permitted or required by applicable Indian laws. In the event it is no longer required for us to retain your personal information, we dispose it off in a secure manner according to our relevant policies.<br/><br/>
                    All information gathered by us is securely stored within our Edfora's database, which is hosted on a server in a third-party cloud. We have been assured by our service provider that the database is stored on servers secured behind a firewall; access to such servers being password-protected and strictly limited based on need-to-know basis. However, we understand that as effective as our security measures are, no security system is impenetrable. Thus, we cannot guarantee the security of Edfora database, nor can we guarantee that information you supply will not be intercepted while being transmitted to us over the Internet. Our website is scanned on a regular basis for security holes and known vulnerabilities in order to make your visit to our site as safe as possible.</p>
                    <h3 className='titleHead'>Withdraw consent</h3>
                    <p>If you withdraw your consent for the use or disclosure of your personal information for purposes set out in this privacy policy, you may not have full access to all our services and we might not be able to provide you all of the services and customer support offered to our users and authorized under this Privacy policy and our terms of use. In accordance with Information Technology Act 2000 and the rules made there under, the name and contact details of the grievance officer who can be contacted with respect to any complaints or concerns including those pertaining to breach of Edfora's terms of use, user policy and other policies or questions are published as under:<br/><br/>
                        <strong className='fmedium'>Grievance Officer:</strong><br/>
                        <strong className='fmedium'>Bhaskar Roy</strong><br/>
                        <strong className='fmedium'>Email address:</strong> <a className='linkUnderline' href="mailto:bhaskar.roy@edfora.com">bhaskar.roy@edfora.com</a>
                    </p>
                    <h5 className='subText'>Any questions or clarifications with respect to this policy can be sent to Edfora.</h5>
                    <h5 className='subText lssmall'>EDFORA INFOTECH PRIVATE LIMITED<br/>
                        <span className='fLight'>2nd Floor, KH No 274,<br /> Plot No. 5, Saidulajab Extn., Westend Marg,<br /> New Delhi 110030

</span></h5>
                </Container>
            </section>
        </div>
    )
}

export default PrivacyPolicy;