import React from 'react'
import { Container } from 'react-bootstrap';
import BannerInside from '../components/Bannerinside/Bannerinside'
function policyinfo() {
    return (
        <div className="aboutUs">
            <BannerInside PageTitle="Policy & Other Info" PageSubTitle=""></BannerInside>
            <section className='innerPageSec contentArea'>
                <Container>
                <div className="boxTopNew">
                        <div className="pdfBlocks">
                        <div className='columns'>
                            <span><img src="/images/pdf-icon.svg" alt='pdf' /> e-Form MGT 7.pdf</span>
                            <a href='https://mypat-v2-prod.s3.us-west-2.amazonaws.com/webcontent/edfora/Form_MGT_7+Edfora.pdf' target='_blank' rel='noopener noreferrer' title='e-Form MGT 7.pdf'>View</a>
                        </div>
                        <div className='columns'><span><img src="/images/pdf-icon.svg" alt='pdf' /> Notice of AGM.pdf</span>
                        <a href='https://mypat-v2-prod.s3.us-west-2.amazonaws.com/webcontent/edfora/Notice+of+AGM.pdf' target='_blank' rel='noopener noreferrer' title='Notice of AGM.pdf'>View</a></div>
                        </div>
                    </div>
                </Container>
            </section>
        </div>
    )
}
export default policyinfo;