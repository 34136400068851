const development = {
    edforaBaseURL: 'https://fb1-api-v4.mypat.in',
}
const feature1 = {
    edforaBaseURL: 'https://fb1-api-v4.mypat.in',
}

const production = {
    edforaBaseURL: 'https://v4-auth-service.mypat.in',
}

const config: any = {
    development,
    feature1,
    production,
}

// default config
let configBasedOnEnv = {
    ...config['development']
}

//config based on ENV
if (process.env.REACT_APP_ACTIVE_ENV && config[process.env.REACT_APP_ACTIVE_ENV]) {
    configBasedOnEnv = config[process.env.REACT_APP_ACTIVE_ENV];
}
export default configBasedOnEnv;
