import { Col, Container, Row } from "react-bootstrap";
import BannerInside from "../components/Bannerinside/Bannerinside";
import React, { useState } from "react";
import { getProgramDetailsFromRegistrationNumber } from "../ProgramMappings/programMappings";

interface EnrolmentData {
    registrationNumber: string;
    class: string;
    program: string;
    fee: string;
    note?: string; 
}

function FiitjeeProducts() {
    const [enrolmentNo, setUsername] = useState<string>("");
    const [submittedData, setSubmittedData] = useState<EnrolmentData[]>([]);
    const [error, setError] = useState<string | null>(null);

    const handleFormSubmit = (e: any) => {
        e.preventDefault();
        setSubmittedData([]);
        try {
            const programDetails = getProgramDetailsFromRegistrationNumber(enrolmentNo);
            setSubmittedData([...programDetails]);
            setError(null);
        } catch (err: any) {
            setError(err.message);
        }
    };

    return (
        <div className="App">
            <div className="ContactUs">
                <BannerInside
                    PageTitle="EDFORA PRODUCTS"
                    PageSubTitle="Exclusively For FIITJEE Students"
                />
                <Container className="contentArea">
                    <div className="boxTop pa-0 radius20 regs-container">
                        <Row className="contactMobileView">
                            <Col xs={12} lg={12} xl={12}>
                                <div className="contactForm fiitjee-regs-number">
                                    <form onSubmit={handleFormSubmit}>
                                        <div className="regs-number">
                                            <input
                                                className="form-control"
                                                type="number"
                                                name="enrolmentNo"
                                                placeholder="Enter Registration No."
                                                autoComplete="off"
                                                required
                                                value={enrolmentNo}
                                                onChange={(e) => setUsername(e.target.value)}
                                            />
                                            <button type="submit" className="btn btn-primary" disabled={!enrolmentNo}>
                                                Submit
                                            </button>
                                            {error && <span className="error-msg">{error}</span>}
                                        </div>
                                    </form>

                                    {submittedData?.length > 0 &&
                                    <div className="table-responsive mt-30">
                                        <table className="table table-bordered">
                                            <tbody>
                                                <tr>
                                                    <td>Registration Number</td>
                                                    <td>Class</td>
                                                    <td>Program</td>
                                                    <td rowSpan={submittedData[0]?.note ? 3 : undefined}>Fee</td>
                                                    {/* {submittedData.length > 1 && 
                                                        <td>Note</td>
                                                    } */}
                                                </tr>
                                                {submittedData.map((data, index) => (
                                                    <tr>
                                                        <td>{data.registrationNumber}</td>
                                                        <td>{data.class}</td>
                                                        <td>{data.program}</td>
                                                        <td rowSpan={data?.note ? 3 : undefined}>{data.fee}</td>
                                                        {/* {submittedData.length > 1 && 
                                                        <td>{data.note || '-'}</td>
                                                        } */}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        <p>** GST additional</p>
                                    </div>
                                    }
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </div>
    );
}

export default FiitjeeProducts;
