import { VALID_CLASS_VALUES, classWiseProgramDetailsMappings, VALID_REGISTRATION_LENGTH } from './programMappings.constants';

function getProgramDetailsFromRegistrationNumber(registrationNumber: string){
    if (typeof registrationNumber !== 'string' || registrationNumber.length < VALID_REGISTRATION_LENGTH) {
        throw new Error('Invalid Registration Number');
      }
    const classVal = registrationNumber.charAt(0) + registrationNumber.charAt(1);
    if (!VALID_CLASS_VALUES.has(classVal)) {
        throw new Error(`Program Details not found for Registration Number ${registrationNumber}`);
    }
    const programDetails = classWiseProgramDetailsMappings[classVal];
    if (!programDetails) {
        throw new Error(`Program Details not found for Registration Number ${registrationNumber}`);
    }
    return Array.isArray(programDetails)
        ? programDetails.map(detail => ({ ...detail, registrationNumber }))
        : { ...programDetails, registrationNumber };
}

export { getProgramDetailsFromRegistrationNumber };
