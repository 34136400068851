import React from 'react';
import './Bannerinside.scss';
import { Container } from 'react-bootstrap';


function BannerInside (props:any) {
    return (
        <div className="InsidePages">
        <div className="bannerBgInsideContainer">
            <div className='bannerBgInsideView'>
                <Container>
                    <div className='heroBannerContent innerBannerView'>
                        
                        <img className='starAnimate' src="/images/sec-star.png" alt="Star" />
                        <h1 className='bannerTitlePage'>{props.PageTitle}</h1>
                        <p className='bannerContent m-0'>{props.PageSubTitle}</p>
                    </div>
                </Container>
            </div>
        </div>
    </div>
    )
}

export default BannerInside;