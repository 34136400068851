import React from 'react'
import { Container } from 'react-bootstrap';
import BannerInside from '../components/Bannerinside/Bannerinside'
function Aboutus() {
    return (
        <div className="aboutUs">
            <BannerInside PageTitle="Journey So Far…" PageSubTitle="We focalise on Education through Technology"></BannerInside>
            <section className='innerPageSec contentArea'>
                <Container>
                    <div className="boxTop">
                        <h2 className="secTitle position-relative text-center">About Us</h2>
                        <p>Edfora Infotech Private Limited, incorporated on 01 June, 2012, is an EdTech startup. Edfora stands for Education for All and pledges to make education inclusive and accessible leveraging the power of technology. At Edfora, we develop differentiated learner centric platforms and products - those that positively impact learners the world over. We are determined to incrementally cater to all pursuits of human development and vow to remain unsettled until we become indispensably relevant to each learner on the planet.<br /><br />We focalise on improving the experience and outcomes of Learning. We are engaged in the creation of transformative platforms that will have a deep impact in the learning ecosystems globally.</p>
                    </div>
                    <div className="boxTop insideNoBox">
                        <h2 className="secTitle position-relative text-center">Core Values</h2>
                        <p>We remain ever guided about the purpose of our existence by constantly reflecting upon our Core Values. They find expression within the value system of all our Platforms, Products & People.</p>
                    </div>
                    <div className='blocksCard'>
                        <div className="blocks">
                            <img src="/images/ownership.png" alt='Ownership' />
                            <b>Ownership</b>
                            <span>We own and undertake the responsibility to positively transform every learner.</span>
                        </div>
                        <div className="blocks">
                            <img src="/images/learner.png" alt='Learner Centric' />
                            <b>Learner Centric</b>
                            <span>All our platforms are conceived and built keeping the learners interests at the core - "Of the Learner, For The Learner & By The Learner".</span>
                        </div>
                        <div className="blocks">
                            <img src="/images/innovation.png" alt='Innovation' />
                            <b>Innovation</b>
                            <span>Innovation is the core of Edfora's culture and it manifests itself in all our actions.</span>
                        </div>
                        <div className="blocks">
                            <img src="/images/thinkbig.png" alt='Think Big' />
                            <b>Think Big</b>
                            <span>We continue to incessantly chase the “Unknown Extraordinariness” in our thoughts and actions.</span>
                        </div>
                        <div className="blocks">
                            <img src="/images/outcome.png" alt='Outcome Oriented' />
                            <b>Outcome Oriented</b>
                            <span>We primarily focus on timely accomplishment of Goals that involves delivering outcomes of the highest standards.</span>
                        </div>
                        <div className="blocks">
                            <img src="/images/divedeep.png" alt='Dive Deep' />
                            <b>Dive Deep</b>
                            <span>We foster an “eye for detail” in our people for both macro and micro aspects at work.</span>
                        </div>
                    </div>
                </Container>
            </section>
        </div>
    )
}
export default Aboutus;