import React from 'react';
import Marquee from "react-fast-marquee";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './App.scss';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function Home() {
  return (
    <div className="app">
        <section className='mainBanner'>
          <div className='bannerBgView'>
            <Container>
              <div className='heroBannerContent'>
                <img className='starAnimate' src="/images/sec-star.png" alt="Star" />
                <h1 className='bannerTitle'>Transformative Platforms <br /> for Learners & allied Stakeholders</h1>
                <p className='bannerContent m-0'>Edfora believes that excellence in learning can be achieved at scale for all learners across all human pursuits through transformative <strong>Ed-Tech</strong> Platforms</p>
              </div>
              <div className="heroBannerGraphics"><img src="/images/banner-graphics.png" alt="Banner" /></div>
            </Container>
          </div>
        </section>
        <section className='achieveSec'>
          <Container>
            <h2 className="secTitle position-relative text-center">We vow to achieve our...</h2>
            <Row>
              <Col className='py-3 py-lg-0' md={6} lg={3}>
                <div className="h-100 achieveBlock vision text-center">
                  <div className="achieveIcon"><img src="/images/vision.png" alt="vision" /></div>
                  <h3 className="achieveSecTitle text-uppercase">Vision</h3>
                  <p className="achieveSecContent">Achieve holistic human development through transformative learning platforms. This vision is not realized until  ALL, repeat ALL, LEARNERS are transformed</p>
                </div>
              </Col>
              <Col className='py-3 py-lg-0' md={6} lg={3}>
                <div className="h-100 achieveBlock mission text-center">
                  <div className="achieveIcon"><img src="/images/mission.png" alt="mission" /></div>
                  <h3 className="achieveSecTitle text-uppercase">Mission</h3>
                  <p className="achieveSecContent">Edfora has ventured to Enrich, Improvise and Augment the education ecosystems globally by maximising outcomes for learners, and thus establish a progressive, pluralistic, scientific and spiritual global society</p>
                </div>
              </Col>
              <Col className='py-3 py-lg-0' md={6} lg={3}>
                <div className="h-100 achieveBlock pledge text-center">
                  <div className="achieveIcon"><img src="/images/pledge.png" alt="pledge" /></div>
                  <h3 className="achieveSecTitle text-uppercase">Pledge</h3>
                  <p className="achieveSecContent">Through our platforms, we vow to empower, a wide variety of learners chasing a myriad of goals across a multitude of disciplines spanning the entire gamut <br />of <br />The Holistic Human Development Spectrum</p>
                </div>
              </Col>
              <Col className='py-3 py-lg-0' md={6} lg={3}>
                <div className="h-100 achieveBlock goal text-center">
                  <div className="achieveIcon"><img src="/images/goal.png" alt="goal" /></div>
                  <h3 className="achieveSecTitle text-uppercase">Goal</h3>
                  <p className="achieveSecContent"> To make platforms that become indispensable in the educational ecosystems globally</p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="ourProductSec">
          <Container>
            <div className="ourProductBlock">
              <h2 className="secTitle position-relative text-center">Our Products</h2>
              <div className="ourProductSliderSec">
              <Marquee className='home-slider-marquee' speed={150} pauseOnHover={true}>
                {/* <div className='ourProductsView'>
                  <div className='productsIconDesc'>
                    <div className="productIcon"><img src="/images/product-mayPat.svg" alt="myPat" /></div>
                    <p className="ourProductDesc">A B2C diagnostic platform that features practice, assessment and microanalytics for JEEM, JEEA, BITSAT & NTSE.</p>
                  </div>
                  <Link className='productMoreLink' to={{pathname: '/products', hash: 'mypatFiitjee'}}>Explore More <img src='/images/arrow-right.svg' alt='Explore More' /></Link>
                </div> */}
                <div className='ourProductsView'>
                  <div className='productsIconDesc'>
                    <div className="productIcon"><img src="/images/student-edfora.svg" alt="Edfora Student" /></div>
                    <p className="ourProductDesc">This is a B2B2C augmented learning management system for test prep. It optimizes the learning journey of every student with personalization.</p>
                  </div>
                  <Link className='productMoreLink' to={{pathname: '/products', hash: 'productGlorifire'}}>Explore More <img src='/images/arrow-right.svg' alt='Explore More' /></Link>
                </div>
                <div className='ourProductsView'>
                  <div className='productsIconDesc'>
                    <div className="productIcon"><img src="/images/stakeholder-edfora.svg" alt="Edfora Stakeholder" /></div>
                    <p className="ourProductDesc">A B2B2C  platform designed for seamless and meaningful collaboration amongst stakeholders, crucial to the success of a student.</p>
                  </div>
                  <Link className='productMoreLink' to={{pathname: '/products', hash: 'productStakeholder'}}>Explore More <img src='/images/arrow-right.svg' alt='Explore More' /></Link>
                </div>
                <div className='ourProductsView'>
                  <div className='productsIconDesc'>
                    <div className="productIcon"><img src="/images/ops-edfora.svg" alt="Edfora Ops" /></div>
                    <p className="ourProductDesc">A B2B proprietary CRM to manage all our platforms</p>
                  </div>
                  <Link className='productMoreLink' to={{pathname: '/products', hash: 'productGlorifireOpps'}}>Explore More <img src='/images/arrow-right.svg' alt='Explore More' /></Link>
                </div>
                <div className='ourProductsView'>
                  <div className='productsIconDesc'>
                    <div className="productIcon"><img src="/images/authors-edfora.svg" alt="Edfora Authors" /></div>
                    <p className="ourProductDesc">A B2B platform that manages the life cycle of academic content and workflows including authoring, proprietary tagging and contextual repurposing.</p>
                  </div>
                  <Link className='productMoreLink' to={{pathname: '/products', hash: 'productGlorifireAuthors'}}>Explore More <img src='/images/arrow-right.svg' alt='Explore More' /></Link>
                </div>
              </Marquee>
              </div>
            </div>  
           </Container>
        </section>
        <section className='ceoDeskSec'>
          <Container>
            <div className="ceoBlock">
              <div className="leftBlock"><img src="/images/ceo-icon.png" alt="CEO's" /></div>
              <div className="rightBlock w-100">
                <div className="rightBlockBox">
                  <h2 className="secTitle position-relative text-center">From The CEO's Desk</h2>
                  <p className="ceoDeskContent text-center"><img className='quote' src="/images/quote-start.svg" alt="quote" /> We are on a mission to redraw the contours of how education is imparted around the world. As a team of Hustlers, we Love to Create solutions that Optimize for Impact and empower humanity through Educational Outcomes. We build learner centric platforms and products that enable them to achieve their true potential. <img className='quote end' src="/images/quote-end.svg" alt="quote" /></p>
                  <div className="ceoName text-center">Udit Sawhney, CEO</div>
                </div>
              </div>
            </div>
          </Container>
        </section>
        <section className='joinUsSec'>
          <Container>
            <div className="joinUsBlock">
              <img className='starAnimate' src="/images/sec-star.png" alt="Star" />
              <h2 className="secTitle position-relative text-center">We Build What Users Love</h2>
              <p className="secSubTitle text-center mx-auto">No matter how agile the world is, our Platforms continuously evolve to keep pace with the advancements in technology, changing customer needs, insights and aspirations</p>
              <div className="joinUsView">
                <div className="joinUsData">
                  <h3 className="joinUsFirstTitle"><div className="animatedText">Want to shape</div></h3>
                  <h3 className="joinUsSecondTitle"><div className="animatedText">the "<span className='text-uppercase'>Future</span> of EdTech"?</div></h3>
                  <a href="/careers" className='joinUsCTA text-center'>Join Us</a>
                </div>
                <div className="joinUsAnimate"><img src="/images/joinUsAnimate.png" alt="Join Us" /></div>
              </div>
            </div>
          </Container>
        </section>
    </div>    
  )
}
export default Home;