import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ScrollToTop from './ScrollToTop';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import Home from './Home';
import Aboutus from './Aboutus/Aboutus';
import Careers from './Careers/Careers';
import Contact from './Contact/Contact';
import Products from './Products/products';
import PrivacyPolicy from './PrivacyPolicy/privacypolicy';
import Copyright from './Copyright/copyright';
import TermsService from './TermsService/termsservice';
import PageNotFound from './PageNotFound';
import InternShip from './internShip/internShip';
import PolicyInfo from './PolicyInfo/PolicyInfo'
import FiitjeeProducts from './FiitjeeProducts/FiitjeeProducts';

function App() {
  return (
    <div className="App">
        <Router>
          <ScrollToTop>
          <Header />
          <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="/aboutus" element={<Aboutus/>} />
            <Route path="/careers" element={<Careers/> ? <Careers /> : <InternShip />} />
            <Route path="/contact" element={<Contact/>} />
            <Route path="/products" element={<Products/>} />
            <Route path='/privacypolicy' element={<PrivacyPolicy/>} />
            <Route path='/copyright' element={<Copyright/>} />
            <Route path='/termsservice' element={<TermsService/>} />
            <Route path='/policyinfo' element={<PolicyInfo/>} />
            <Route path='/FIITJEE_products' element={<FiitjeeProducts/>} />
            {/* <Route path='/internshipprogramme' element={<InternShip/>} /> */}
            <Route path='*' element={<PageNotFound/>} />

            <Route path="/internshipprogramme" element={<InternShip/> ? <InternShip /> : <Careers />} /> 


          </Routes>
          <Footer />
          </ScrollToTop>
        </Router>
      </div>
  );
}

export default App;