const VALID_CLASS_VALUES = new Set(['60', '70', '80', '90', '10', '11', '12']);
const classWiseProgramDetailsMappings: any = {
    '60': [{ 'program': 'Edfora One Year Program', 'class': 'Class VI', 'fee': 'Rs 5000/-' }],
    '70': [{ 'program': 'Edfora Two Year Program', 'class': 'Class VII', 'fee': 'Rs 5000/-' }],
    '80': [
        { 'program': 'Edfora One Year Program', 'class': 'Class VIII', 'fee': 'Rs 5000/-' },
        { 'program': 'Edfora Three Year Program', 'class': 'Class VIII', 'fee': 'Rs 19000/-' },
    ],
    '90': [
        { 'program': 'Edfora Two Year Program', 'class': 'Class IX', 'fee': 'Rs 7000/-' },
        { 'program': 'Edfora Four Year Program', 'class': 'Class IX', 'fee': 'Rs 34000/-' },
    ],

    '10': [
        { 'program': 'Edfora One Year Program', 'class': 'Class X', 'fee': 'Rs 7000/-' },
        { 'program': 'Edfora Three Year Program', 'class': 'Class X', 'fee': 'Rs 27000/-' },
    ],
    '11': [{ 'program': 'Edfora Two Year Program', 'class': 'Class XI', 'fee': 'Rs 10000/-' }],
    '12': [{ 'program': 'Edfora One Year Program', 'class': 'Class XII', 'fee': 'Rs 10000/-' }],
};
const VALID_REGISTRATION_LENGTH = 16;

export { VALID_CLASS_VALUES, classWiseProgramDetailsMappings, VALID_REGISTRATION_LENGTH };