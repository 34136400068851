import React, { useState, useEffect, useRef } from 'react'
import './products.scss'
import BannerInside from '../components/Bannerinside/Bannerinside'
import { Container, Row, Col } from 'react-bootstrap'

function Products() {
    const [isActive, setIsActive] = useState(false);
    const [myClass, setMyClass] = useState(false);
    function handleClick(value: any) {
        setIsActive(current => !current);
        setMyClass(current => !current);
    };

    const mypatFiitjeeRef = useRef<any>(null);
    const productGlorifireRef = useRef<any>(null);
    const productStakeholderRef = useRef<any>(null);
    const productGlorifireOppsRef = useRef<any>(null);
    const productGlorifireAuthorsRef = useRef<any>(null);
    const elDict = {
        '#mypatFiitjee': mypatFiitjeeRef,
        '#productGlorifire': productGlorifireRef,
        '#productStakeholder': productStakeholderRef,
        '#productGlorifireOpps': productGlorifireOppsRef,
        '#productGlorifireAuthors': productGlorifireAuthorsRef
    }

    useEffect(() => {
        const hash = window.location.hash;
        try {
            // @ts-ignore
            elDict[hash].current.scrollIntoView({ behavior: 'smooth' });
        }
        catch (e) { }
    });


    return (
        <div className="products">
            <BannerInside PageTitle="Our Products" PageSubTitle="to make users Fall in Love…"></BannerInside>
            <div className='greyBox'>
                <Container>
                    <b>Conceived for impacting humanity right from the foetus in the womb to life after death</b>
                </Container>
            </div>
            <Container>
                {/* <Row id='mypatFiitjee' ref={mypatFiitjeeRef} className="gridBlocks">
                    <Col xl={6}>
                        <div className='lftImg d-block d-md-none'>
                            <img src='/images/pro-mypatFiitjee.svg' alt='myPat Fiitjee' />
                        </div>
                        <div className='lftImg productMyPatFiitjee d-none d-md-block'>
                            <img className='productMyPatFiitjeeOne' src='/images/product-myPatFiitjee1.png' alt='myPat Fiitjee' />
                            <img className='productMyPatFiitjeeTwo' src='/images/product-myPatFiitjee2.png' alt='myPat Fiitjee' />
                            <img className='productMyPatFiitjeeThree' src='/images/product-myPatFiitjee3.png' alt='myPat Fiitjee' />
                            <div className='productMyPatFiitjeeFour'><img src='/images/product-myPatFiitjee4.svg' alt='myPat Fiitjee' /></div>
                        </div>
                    </Col>
                    <Col xl={6}>
                        <div className="proName">
                            <span><img src='/images/mypatFiitjee.svg' alt='mypatFiitjee' /></span>
                            <span>Powered by<br /><b>Edfora</b></span>
                        </div>
                        <p>A diagnostic platform that features assessment and microanalytics for JEEM, JEEA, BITSAT & NTSE. With mock tests, myPAT tracks accomplishment and identifies areas for improvement.</p>
                        <b className='titlePara'>What you can do on myPAT FIITJEE?</b>
                        <ul>
                            <li>Experience simulated practice - tests for JEE Main, JEE Advanced, BITSAT and NTSE</li>
                            <li>Review your Performance with custom micro analytics report</li>
                            <li>Identify strengths and spot weaknesses</li>
                            <li>Solved Previous Year Papers</li>
                            <li>Boost your test preparation with exclusive eBooks and hand-picked blogs by experts</li>
                            <li>Community for queries and doubts</li>
                        </ul>
                    </Col>
                </Row> */}
                <Row id='productGlorifire' ref={productGlorifireRef} className="gridBlocks productMobileView">
                    <Col xl={6}>
                        <div className="proName">
                            <span><img src='/images/student-edfora.svg' alt='Student' /></span>
                        </div>
                        <b className='titlePara'>Augmented LMS - every learner wants</b>

                        <p>The game-changing app for test preparation. It enables learners to hone and harness their 100% potential. It is tailored to test prep targets like JEEM, JEEA, BITSAT and NTSE.
                            
                            <br /><br />Edfora Student curates the learning journey by objectifying abstractness in the curriculum. This makes learning insightful and measurable! These objectives however vary from one learner to another. This is achieved through personalisation of the learning journey for every student. It enables identification of individuals uniquely on the curricular-spectra vis-à-vis specific goal(s) viewed from the lens of their potency. <span className={`viewMore ${isActive ? 'viewMoreToggle' : ''}`} onClick={() => handleClick(true)}>View More</span>
                            <span className={`viewMoreDesc ${myClass ? 'viewMoreDescToggle' : ''}`}>
                                
                            <br/>The depth in differentiation comes from atomic analysis of the curriculum. Its propriety algorithms dissect the curriculum spectroscopically and microscopically, and applies the filter of potency of an individual to prescribe a winning path or personalised learning journey. This yields disproportionate gains for learners placing Edfora Student in a different league from other LMSs altogether. <br/><br/>
                            
                            <ul>
                                <li>It works to optimise achievement for a learner thereby ensuring that the learning outcomes are commensurate with the learner's potential.</li>
                                <li> It presents intelligence in the form of vital indices that are relatable and pertinent to a learner and empowers them with tools for remedial intervention.</li>
                                <li>  It provisions strategic collaboration amongst the learner and other stakeholders crucial to the learner's success.</li>
                                <li> It features remedial micro-interventions to help learners achieve their absolute best (their 100%).
                            </li>
                            </ul>
                            This pedagogical shift is in stark contrast to the one-size-fits-all frameworks that have plagued learning ecosystems globally both in terms of experience and outcomes. This is what we call as success and Edfora Student aims to achieve it for 100% of the learners as they become the best version of themselves. <span className={`viewLess ${isActive ? 'viewLessToggle' : ''}`} onClick={() => handleClick(true)}>View Less</span></span>
                        </p>
                    </Col>
                    <Col xl={6}>
                        <div className='lftImg d-block d-md-none'>
                            <img src='/images/pro-Glorifire.svg' alt='Glorifire' />
                        </div>
                        <div className='lftImg productGlorifire d-none d-md-block'>
                            <img className='productGlorifireOne' src='/images/product-glorifire1.png' alt='Glorifire' />
                            <img className='productGlorifireTwo' src='/images/product-glorifire2.png' alt='Glorifire' />
                            <img className='productGlorifireThree' src='/images/product-glorifire3.png' alt='Glorifire' />
                            <div className="productGlorifireFour"><img src='/images/product-glorifire4.svg' alt='Glorifire' /></div>
                            <img className='productGlorifireFive' src='/images/product-glorifire5.png' alt='Glorifire' />
                        </div>
                    </Col>
                </Row>
                <Row id='productStakeholder' ref={productStakeholderRef} className="gridBlocks">
                    <Col xl={6}>
                        <div className='lftImg d-block d-md-none'>
                            <img src='/images/pro-GlorifireStakeholder.svg' alt='GlorifireStakeholder' />
                        </div>
                        <div className='lftImg productGlorifireStakeholder d-none d-md-block'>
                            <img className='productGlorifireStakeholderOne' src='/images/productGlorifireStakeholder1.png' alt='Glorifire Stakeholder' />
                            <img className='productGlorifireStakeholderTwo' src='/images/productGlorifireStakeholder2.png' alt='Glorifire Stakeholder' />
                            <img className='productGlorifireStakeholderThree' src='/images/productGlorifireStakeholder3.png' alt='Glorifire Stakeholder' />
                            <img className='productGlorifireStakeholderFour' src='/images/productGlorifireStakeholder4.png' alt='Glorifire Stakeholder' />
                        </div>
                    </Col>
                    <Col xl={6}>
                        <div className="proName">
                            <span><img src='/images/stakeholder-edfora.svg' alt='Stakeholder' /></span>
                        </div>
                        <p>Edfora Stakeholder empowers Teachers with a variety of tools to efficiently manage the batches they teach as well as every student seamlessly. They get first-hand experience of this holistic Learning Management System through a dedicated Teacher's dashboard having a simplified, intuitive, and engaging user interface with distinguished features. The platform ushers a generational transformation fostering Student centricity in Academic delivery. Teachers witness a Quantum jump in Academic Planning and Management. Several workflows and processes have been designed to give a much neater, richer and fuller teaching experience.</p>
                    </Col>
                </Row>
                <Row id='productGlorifireOpps' ref={productGlorifireOppsRef} className="gridBlocks productMobileView">
                    <Col xl={6}>
                        <div className="proName">
                            <span><img src='/images/ops-edfora.svg' alt='Ops' /></span>
                        </div>
                        <p>Edfora Ops is an admin dashboard to manage our B2B, B2B2C and B2C Platforms. It coherently manages Students, Stakeholders, Batches, Centres and Institutes with high efficiency in a very easy and user friendly manner.<br /><br />It also provides detailed and accurate reports in the form of MISs for managing the Centre/Institute as desired. By eliminating loads of manual work and repetitive tasks, Edfora Ops helps in optimising time and resources.</p>
                    </Col>
                    <Col xl={6}>
                        <div className='lftImg d-block d-md-none'>
                            <img src='/images/pro-GlorifireOps.svg' alt='GlorifireOps' />
                        </div>
                        <div className='lftImg productGlorifireOps d-none d-md-block'>
                            <img className='productGlorifireOpsOne' src='/images/productGlorifireOps1.png' alt='Glorifire Ops' />
                            <img className='productGlorifireOpsTwo' src='/images/productGlorifireOps2.png' alt='Glorifire Ops' />
                            <img className='productGlorifireOpsThree' src='/images/productGlorifireOps3.png' alt='Glorifire Ops' />
                        </div>
                    </Col>
                </Row>
                <Row id='productGlorifireAuthors' ref={productGlorifireAuthorsRef} className="gridBlocks">
                    <Col xl={6}>
                        <div className='lftImg d-block d-md-none'>
                            <img src='/images/pro-GlorifireAuthors.png' alt='GlorifireAuthors' />
                        </div>
                        <div className='lftImg productGlorifireAuthors d-none d-md-block'>
                            <img className='productGlorifireAuthorsOne' src='/images/productGlorifireAuthors1.png' alt='Glorifire Authors' />
                            <img className='productGlorifireAuthorsTwo' src='/images/productGlorifireAuthors2.png' alt='Glorifire Authors' />
                            <img className='productGlorifireAuthorsThree' src='/images/productGlorifireAuthors3.png' alt='Glorifire Authors' />
                            <div className="productGlorifireAuthorsFour">
                                <img src='/images/productGlorifireAuthors4.svg' alt='Glorifire Authors' />
                            </div>
                        </div>
                    </Col>
                    <Col xl={6}>
                        <div className="proName">
                            <span><img src='/images/authors-edfora.svg' alt='Authors' /></span>
                        </div>
                        <ul>
                            <li>The seamless experience of creating, publishing and rendering learning content of all types is truly a high point of the Platform  which comes with a host of utilities and features for Teachers. It coherently manages and supports the Teachers in the Academic delivery end-to-end.</li>
                            <li>Edfora Authors offers Teachers to create, review, approve, manage and modify content with ease.</li>
                            <li>Its front-end user interface allows a user, to add, modify and remove content with ease. The platform lets a user create academic content, questions, courses and manage templates. Our powerful academic content editor allows typing and editing of the content in text and other formats.</li>
                            <li>Authors are able to punch metadata in the form of predefined concept codes and tags.</li>
                            <li>It has tools to generate a variety of tests by specifying the contours in the form of rules and defining the parameters of evaluation.</li>
                        </ul>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
export default Products;