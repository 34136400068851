import React from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import BannerInside from '../components/Bannerinside/Bannerinside';

function TermsService(){
    return(
        <div className='innerPageBgBlock'>
            <BannerInside PageTitle="Terms of Service"></BannerInside>
            <section className='innerPageDetails'>
                <Container>
                    <p>Welcome to Edfora Infotech Private Limited, you are requested to read them carefully before you use the services of this site. Please understand that your use of <Link to="/">Edfora</Link> or any of the products or services offered on the website (collectively, the "Services") is subject to these terms of use (collectively, the "Terms"). By using or visiting the website, the Student/user, as defined herein below, agrees to be bound by these terms of services.<br/><br/>

                    If you do not agree with any provision of these terms of services, then, you must discontinue using the website and/or discontinue availing the services provided by the website. We reserve the right to modify the terms of services, at any time, without taking your prior consent, or sending notification, as the case maybe and such modified version of the terms of services shall be effective immediately upon its publication on the website and will supersede all previous versions thereof. You, hereby, agree to regularly review the terms to keep yourself apprised of any such changes. Your continued use of the website constitutes your binding acceptance of these terms, including any modifications thereto. These terms of use and the provision of our services shall be governed by and construed in accordance with Indian law. Any dispute arising out of these terms of services shall exclusively be submitted to the competent courts in New Delhi, India.</p>
                    
                    <h3 className='titleHead'>Your Acceptance</h3>
                    <p>Welcome to <Link to="/">Edfora</Link> (the “website”) managed and operated by Edfora (“EDFORA”). By using and/or visiting this website/ webpage (collectively, including all “Content” on <Link to="/">Edfora</Link> / website), you signify your assent to both these terms and conditions (the "Terms of Service") and the terms and conditions of privacy notice, which are published at <Link to="/">Edfora</Link> and which are incorporated herein by reference. If you do not agree to any of these terms, then please do not use the "<Link to="/">Edfora</Link> or access the contents therein."</p>
                
                    <h3 className='titleHead'><Link to="/">Edfora</Link></h3>
                    <p>These terms of use shall apply to all users of <Link to="/">Edfora</Link>, including users who are also contributors of any type of content, information, and other materials or services on <Link to="/">Edfora</Link><br/><br/>
                    The <Link to="/">Edfora</Link> may contain links to third party/ websites that are not owned or controlled by <Link to="/">Edfora</Link> which has no control over and assumes no responsibility for the content, privacy policies or practices of any such third party / websites. In addition, <Link to="/">Edfora</Link> will not and cannot censor or edit the content of any third-party / website. By using the website, you expressly relieve <Link to="/">Edfora</Link> from any and all liability arising from your use of any third-party contents/ website. Accordingly, we suggest you to be aware when you leave the "<Link to="/">Edfora</Link>" and to read the terms and conditions and privacy policy of any other content/ website that you visit.    
                    </p>

                    <h3 className='titleHead'>Who May Use our Services?</h3>
                    <p>You may use our services provided you are eligible to validly enter in a binding contract with Edfora in compliance with these terms and all applicable laws. Any use or access by anyone under the age of 18 is allowed only through a supervising parent/guardian. If you are a minor, The parent/ guardian shall enter into the agreement on behalf of such minor, and bind himself/herself in accordance with all terms and conditions herein. Our decision about grant of access to the services of Edfora is our sole prerogative and our decision in this respect shall be final and binding.<br/><br/>
                        You agree that Edfora reserves its right to take all necessary measures to deny access to its services or the termination of services, if the terms and conditions of the present agreement are not complied with or are contravened or there is any violation of copyright, trademark or other valuable proprietary right as described in this agreement. In order to use the service, you must obtain access to the World Wide Web, either directly or through devices that access web-based content, and pay for the service fees associated with such access. In addition, you must have all equipment necessary to make such a connection to the World Wide Web, including a computer and modem or other access devices; that do not form a part of the service.</p>
                
                    <h3 className='titleHead'>Our License to You</h3>
                    <p>Subject to these terms and our policies (including the Acceptable Privacy Policy, Edfora's B2B Partner Institutions' user code for their students and course-specific eligibility requirements and other terms incase our license to you is extended by such Institutions), we,<Link to="/">Edfora</Link>, hereby grant you a limited, personal, revocable, legal, ethical, non exclusive, non transferable, non commercial, non vindictive, non discriminatory and non racial use of the contents of our website. You will not copy or distribute any part of the website in any medium without prior written authorization from <Link to="/">Edfora</Link>; you shall also not alter or modify any part of the website other than as may be reasonably necessary to use the website for its intended purpose.<br/><br/>
                        You agree not to use or launch any automated system, including without limitation, "robots", "spiders", "offline readers", etc. that accesses the website in a manner that sends more request messages to <Link to="/">Edfora</Link> servers in a given period of time than a human can reasonably produce in the same period by using a convention on-line web browser. Notwithstanding the foregoing, <Link to="/">Edfora</Link> grants the operators of public search engines permission to use spiders to copy materials from the site for the sole purpose of creating publicly available searchable indices of the materials, but not caches or archives of such materials. This website reserves the right to revoke these exceptions either generally or in specific cases. You agree not to collect or harvest any personally identifiable information, including account names, from the website, nor to use the communication systems provided by the website for any commercial solicitation purposes. You agree not to solicit, for commercial purposes, any users of the website with respect to their user submissions.
                    </p>

                    <h5 className='subText'>General terms of use for content</h5>
                    <ol type='1'>
                        <li>We have taken reasonable care in reviewing the contents of the website. In view thereof, you agree and understand that the contents reflect our or our associates expert(s) opinion regarding subject matters contained on the site and such opinion vary from expert to expert and sometimes with passage of time. You understand that information, material and content have been posted by us on Edfora by exercising due diligence and care and are correct and true to the best of our knowledge and intent. You also agree to independently verify the authenticity and veracity of the information, material and content posted on the site. We do not hold any responsibility as to the authenticity and veracity of the information, material and content on the site. You hereby agree to indemnify the site against all actions brought out in this regard. You shall not construe answers, information, material and content on the site as final without exercising necessary due diligence at your end. You alone shall be responsible for any action taken by you on the basis of the information contained on the site and we will not be liable in any manner for the consequences of such action/inaction by you.</li>
                        <li>You hereby agree and undertake that the time limits, if any, stipulated on the website for providing the answers to the queries of the user are only indicative and not definitive. You understand that the queries of the user may not be answered in a time bound manner due to a high volume of queries at a given point of time.</li>
                        <li>You hereby agree that answers to queries requiring detailed and advanced graphics is outside the ambit of these terms. You hereby agree that we may not be able to resolve certain queries due to the highly complex nature of the query, due to our limited resource base as the answers to such queries can only be provided by having access to specialized and highly skilled resources that are not available with us.</li>
                        <li>You are aware and understand that Edfora does not provide any judgment or warranty regarding the authenticity or correctness of the content of other services or sites that the screen links refer to.</li>
                        <li>You are aware and understand that a link to another service or site is not an endorsement of the service or site and Edfora shall not be responsible in any manner whatsoever for the consequences of any act or omission on the part of the user on the basis of the information contained in such other services or sites.</li>
                        <li>Edfora reserves its right to monitor the use of all or any part of its service without any prior intimation or any obligation to the user.</li>
                        <li>You agree that you shall not alter the information, content or material or use of such information, content or material contained on the site for any purpose that constitutes a violation of the copyright of Edfora or of a third-party information provider. You agree not to copy, reproduce, republish, upload, post, transmit or distribute the material in any way either in part or full, for any use without obtaining the prior written consent from Edfora. However, the use of downloadable pdf resources such as sample test papers and previous year papers is permissible but restricted to your personal use only.</li>
                        <li>If you do not agree to any of the terms mentioned in this agreement, then you must exit the site from your browser.</li>
                    </ol>

                    <h3 className='titleHead'>Intellectual Property Rights</h3>
                    <p>The content on the <Link to="/">Edfora</Link>, except all user submissions (as defined below), including without limitation, the text, software, scripts, graphics, photos, sounds, music, videos, interactive features and the like ("Content") and the trademarks, service marks and logos contained therein, are owned by or licensed to <Link to="/">Edfora</Link>, subject to copyright and other intellectual property rights under the laws of India and foreign laws and international conventions. Please refer to our Copyright Notice for further information on the subject.<br/><br/>
                        <strong>Feedback</strong> We welcome your suggestions, ideas, comments, and other feedback regarding the Services ("Feedback"). By submitting any Feedback, you grant us the right to use the Feedback without any restriction or any compensation to you. By accepting your Feedback, <Link to="/">Edfora</Link> does not waive any rights to use similar or related Feedback previously known to <Link to="/">Edfora</Link>, developed by its employees or contractors, or obtained from other sources.
                    </p>

                    <h3 className='titleHead'>Warranty Disclaimer and Liability</h3>
                    <p>The website is controlled and offered by <Link to="/">Edfora</Link> from its facilities in India. <Link to="/">Edfora</Link> makes no representations that the website is appropriate or available for use in other locations. Those who access or use the website from other jurisdictions do so at their own volition and are responsible for compliance with local law. Usage of our website is subject to you agreeing to the warranty disclaimers. Please refer to our Privacy Policy and the extent of our liability in this regard. You agree that you have understood and agreed to terms stipulated therein.</p>
                    <h3 className='titleHead'>Indemnity</h3>
                    <p>You agree to indemnify, defend and hold harmless <Link to="/">Edfora</Link>, its owners and their officers, directors, employees, agents or third party from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney's fees) arising from:
 
                        (i) your use of and access to the <Link to="/">Edfora</Link><br/>
                        (ii) your violation of any term of these terms of service;<br/>
                        (iii) your violation of any third party right, including without limitation any copyright, property, or privacy right; or <br/>
                        (iv) any claim that one of your user submissions caused damage to a third party. This defense and indemnification obligation will survive these terms of service and your use of <Link to="/">Edfora</Link>
                    </p>

                    <h3 className='titleHead'>General</h3>
                    <h5 className='subText'>You agree that:</h5>
                    <ol type='1'>
                        <li><Link to="/">Edfora</Link> shall be deemed solely based in India; and</li>
                        <li><Link to="/">Edfora</Link> shall be deemed a passive website that does not give rise to personal jurisdiction over <Link to="/">Edfora</Link>, either specific or general, in jurisdictions other than New Delhi, India. These terms of service shall be governed by the internal substantive laws of India, without respect to its conflict of laws, principles. Any claim or dispute between you and <Link to="/">Edfora</Link> that arises in whole or in part from <Link to="/">Edfora</Link> shall be decided exclusively by a court of competent jurisdiction located in New Delhi, India.</li>
                        <li>These Terms of Service, together with the privacy notice at <Link to="/privacypolicy">Privacy Policy</Link> and any other legal notices published by<Link to="/">Edfora</Link> on the website, shall constitute the entire agreement between you and <Link to="/">Edfora</Link> concerning the website.</li>
                        <li>If any provision of these terms of service is deemed invalid by a court of competent jurisdiction, the invalidity of such provision shall not affect the validity of the remaining provisions of these terms of service, which shall remain in full force and effect.</li>
                        <li>No waiver of any term of these terms of service shall be deemed a further or continuing waiver of such term or any other term, and <Link to="/">Edfora</Link> failure to assert any right or provision under these terms of service shall not constitute a waiver of such right or provision. <Link to="/">Edfora</Link> reserves the right to amend these terms of service at any time and without notice, and it is your responsibility to review these terms of service for any changes.</li>
                        <li>Your use of the website following any amendment of these terms of service will signify your assent to and acceptance of its revised terms. You and <Link to="/">Edfora</Link> agree that any cause of action arising out of or related to <Link to="/">Edfora</Link> website must commence within one (1) year after the cause of action accrues, otherwise, such cause of action is permanently barred.</li>
                    </ol>

                    <h3 className='titleHead'>Return Policy</h3>
                    <ol type='1'>
                        <li>We do not allow any return/refund in any form once the product is purchased. So, you are advised to make sure that the correct product is purchased.</li>
                        <li>Still if the wrong product is purchased by mistake, the issue must be reported within 24 hours. In such a scenario, a product can be considered for replacement with another of the same value under strict conditions that the already purchased product has not been used.</li>
                    </ol>
                    <p>However, the final decision, in all cases, rests with Edfora.</p>
                </Container>
            </section>
        </div>
    )
}
export default TermsService;