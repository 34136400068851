import React from 'react';


function PageNotFound() {
  return (
    <div className="app">
        <div className="notFound">
            <img src='/images/404.png' alt='Page Not Found' />
            <b>Page not found</b>
            <p>The page you are looking for does not exist or any other error occurred.</p>
            {/* <p><Navbar.Brand href="/">Back to Home</Navbar.Brand></p> */}
        </div>
        </div>
  )
}
export default PageNotFound