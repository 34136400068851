import React from 'react';
import './internShip.scss';
import { Container } from 'react-bootstrap';
import BannerInside from '../components/Bannerinside/Bannerinside'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

function internShip() {
    return (

        <div className='innerPageSec contentArea inter-ship-sec'>
            <Container className="btnBack">
               <a className="backBtnCta" href="/careers"><img alt='' src="/images/backBtn.svg" /></a>
            </Container>
            <BannerInside PageTitle="Internship Programme" btnclass="showBtn"></BannerInside>
            <Container>
                <div className="boxTop">
                    <p>Interning at Edfora offers a remarkable opportunity to immerse oneself in the dynamic realm of EdTech startup business. It provides an avenue to discover one's passions and inspirations, while also exploring potential career paths. This experience allows individuals to gain valuable insights into the professional world, enabling them to gain a competitive advantage in their future careers even before graduating from college or university.<br /><br />
                        Our structured internship programmes are thoughtfully designed to provide a collaborative and open culture, allowing interns to be involved in all facets of our ever-evolving and fast-paced organization.<br /><br />
                        By joining our internship program, you will not only acquire theoretical knowledge but also bolster your curriculum vitae with tangible practical work experience. Edfora's internship is an excellent avenue to discover your potential and contribute to the burgeoning field of education technology.<br /><br />
                        <b>Edfora Internship Programme gives interns the opportunity to choose whether to work in our office in a hybrid mode (half in the office, and half remote), or on fully remote basis.</b>
                    </p>



                </div>

                <div className="tabsData">
                    <Tabs>
                        <TabList className='mainTabs' >
                            <Tab className='mainTabItem'>Product Marketing Intern</Tab>
                            <Tab className='mainTabItem'>UX Design Intern</Tab>
                            <Tab className='mainTabItem'>Content Intern</Tab>
                            <Tab className='mainTabItem'>Business Strategy Intern</Tab>
                        </TabList>
                        {/* tab 1 start */}
                        <TabPanel className='subTabs'>
                            <Tabs defaultIndex={0}>
                                <TabList>
                                    <Tab>Overview</Tab>
                                    <Tab>Roles and Responsibilities</Tab>
                                    <Tab>Eligibility</Tab>
                                    <Tab>Application Process</Tab>
                                    <Tab>Selection Process</Tab>
                                </TabList>
                                <TabPanel className='contentTabs'>
                                    <p>
                                        The Product Marketing Internship Program presents a valuable opportunity for students to engage in a hands-on experience specifically focused on product marketing within a dynamic and forward-thinking environment. This carefully crafted program is designed to offer comprehensive learning, ensuring that interns gain practical skills and knowledge in strategic marketing, product positioning, and market research.<br /><br />

                                        During the internship, participants will have the privilege of working closely with cross-functional teams, fostering a collaborative atmosphere where their contributions will directly impact the success of product launches and marketing campaigns. This immersive experience will enable interns to develop a deeper understanding of the intricacies involved in bringing a product to market successfully.<br /><br />

                                        With an emphasis on practical application, the program aims to equip interns with the tools and expertise required to excel in the realm of product marketing. Through hands-on involvement and exposure to real-world scenarios, interns will be better prepared to face the challenges and opportunities that arise in the field of marketing.<br /><br />

                                        By participating in the Product Marketing Internship Program, students can elevate their knowledge and proficiency, making them more adept and competitive in their pursuit of a successful marketing career. This unique opportunity promises to be a steppingstone towards a rewarding future in the world of marketing and product promotion.
                                    </p>

                                </TabPanel>
                                <TabPanel className='contentTabs'>
                                    <p>
                                        <ol type='1'>
                                            <li>Conduct market research to identify customer needs, competitive landscape, and market trends.</li>
                                            <li>Assist in developing product positioning and messaging strategies.</li>
                                            <li>Support the creation of marketing collaterals, including presentations, case studies, and product documentation.</li>
                                            <li>Collaborate with the marketing team to execute go-to-market plans and product launch activities.</li>
                                            <li>Analyse market data and customer insights to identify growth opportunities.</li>
                                            <li>Develop customer personas and target market segmentation.</li>
                                            <li>Assist in tracking and analysing key performance indicators (KPIs) to evaluate marketing campaign effectiveness.</li>
                                            <li>Collaborate with cross-functional teams, including product management, sales, and design, to ensure cohesive product marketing strategies.</li>


                                        </ol>
                                    </p>

                                </TabPanel>
                                <TabPanel className='contentTabs'>
                                    <p>
                                        <ol>
                                            <li>Undergraduate student who has completed the equivalent of four semesters of college coursework by the time they begin an internship.</li>
                                            <li>Excellent written and verbal communication skills are crucial for effectively conveying marketing messages and creating compelling content.</li>
                                            <li>While not always required, any prior experience in marketing, advertising, market research, or related fields can be an added advantage.</li>
                                            <li>Familiarity with digital marketing channels and tools, such as social media, email marketing, content marketing, and SEO, will be beneficial.</li>
                                            <li>A creative mindset that allows candidates to come up with innovative marketing strategies and solutions is highly desirable.</li>
                                            <li>Basic analytical skills to interpret market data, analyse consumer behaviour, and make data-driven marketing decisions are valuable.</li>
                                            <li>Ability to work collaboratively with cross-functional teams, such as product development, design, and sales, to ensure successful product launches and marketing campaigns.

                                            </li>
                                        </ol>
                                    </p>


                                    <p>
                                        <span><b>Duration of the Internship Programme:</b> 3 Months</span>
                                        <span><b>No. of Openings:</b> 2</span>
                                        <span><b>Compensation and Benefits</b><br /><br />
                                            Fixed Component: Rs 30,000 per month<br /><br />
                                            Variable Component: Rs 30,000 per month<br /><br />
                                            Certificate of Internship will be awarded to all interns who successfully complete their internship program. Full time employment offer might also be given to the top performers.<br /><br />

                                            Feedback and Wrap-up: Team will review intern's performance, provide constructive feedback, and celebrate the successful completion of the internship.</span>

                                    </p>


                                </TabPanel>
                                <TabPanel className='contentTabs'>
                                    <p>
                                        If you are interested in our internship program, kindly send an email to <a href="mailto:hr@edfora.com">hr@edfora.com</a>, including your Statement of Purpose (SOP) and resume. In your SOP, please elaborate on your interest in the program and explain why you believe you would be an excellent fit for the role.<br /><br />
                                        We look forward to receiving your application and learning more about your passion for joining our internship program at Edfora. Should you have any further questions or require additional information, please do not hesitate to reach out to us on <a href="mailto:hr@edfora.com">hr@edfora.com</a>. Thank you for considering Edfora for your internship experience. </p>


                                </TabPanel>
                                <TabPanel className='contentTabs'>
                                    <p>
                                        <ol>
                                            <li>Application Evaluation</li>
                                            <li>Psychometric and Subject Matter Test</li>
                                            <li>Interview</li>

                                        </ol>
                                    </p>


                                </TabPanel>
                            </Tabs>
                        </TabPanel>
                        {/* tab 2 start */}
                        <TabPanel className='subTabs'>
                            <Tabs>
                                <TabList>
                                    <Tab>Overview</Tab>
                                    <Tab>Roles and Responsibilities</Tab>
                                    <Tab>Eligibility</Tab>
                                    <Tab>Application Process</Tab>
                                    <Tab>Selection Process</Tab>
                                </TabList>
                                <TabPanel className='contentTabs'>
                                    <p>
                                        The UX Design Internship Program offers an enriching experience for aspiring UX designers within a dynamic and innovative setting. This comprehensive program is designed to provide interns with hands-on opportunities, fostering the development of practical skills and knowledge in user experience design, usability testing, and user research methodologies. As an intern, you will have the chance to collaborate closely with multidisciplinary teams and contribute to the creation of user-centric design solutions.<br /><br />
                                        During the internship, participants will be immersed in real-world projects, gaining valuable insights into the entire UX design process, from concept ideation to prototyping and iteration. By working alongside experienced professionals, interns will have the chance to refine their design thinking and problem-solving abilities, preparing them for the challenges of a career in UX design.<br /><br />
                                        Through active involvement in the development of digital products and interfaces, interns will learn to empathize with users, understand their needs, and craft intuitive and delightful user experiences. The program places a strong emphasis on user research, enabling interns to gain a deeper understanding of user behaviours and preferences.<br /><br />
                                        Furthermore, the collaborative nature of the program allows interns to interact with various stakeholders, including developers, product managers, and graphic designers, fostering a holistic appreciation for the multidisciplinary aspects of UX design.<br /><br />
                                        By participating in the UX Design Internship Program, you will not only strengthen your skill set but also build a compelling portfolio that showcases your ability to create impactful and user centred design solutions. This internship experience will serve as a steppingstone towards a successful career in UX design and enable you to make a meaningful contribution to the digital landscape.

                                    </p>

                                </TabPanel>
                                <TabPanel className='contentTabs'>
                                    <p>
                                        <ol type='1'>
                                            <li>Collaborate with the UX design team to support the creation of user-centric design solutions for digital products and interfaces.</li>
                                            <li>Assist in conducting user research and usability testing, gathering valuable insights to inform design decisions and improvements.</li>
                                            <li>Participate in brainstorming sessions and design workshops to contribute innovative ideas and concepts for improving user experiences.</li>
                                            <li>Work closely with UX designers to create wireframes, prototypes, and mock-ups that effectively communicate design concepts and interactions.</li>
                                            <li>Conduct competitive analysis and research on industry best practices to identify design trends and opportunities for improvement.</li>
                                            <li>Collaborate with cross-functional teams, including developers and product managers, to ensure seamless integration of design elements and user interactions.</li>
                                            <li>Assist in the creation and maintenance of design documentation, including design guidelines, user flows, and design specifications.</li>
                                            <li>Support the design team in implementing design feedback and iterating on design solutions based on user feedback and data analysis.</li>
                                            <li>Stay up to date with the latest trends, tools, and techniques in UX design, contributing to the team's continuous learning and growth.</li>
                                        </ol>

                                    </p>

                                </TabPanel>
                                <TabPanel className='contentTabs'>
                                    <p>
                                        <ol type='1'>
                                            <li>Class XII Pass students, students pursuing Undergraduate program or those who have recently graduated.</li>
                                            <li>Demonstrated interest in designing.</li>
                                            <li>A passion for continuous learning and staying updated with the latest trends and advancements in UX design is highly regarded.</li>
                                            <li>Having a keen eye for detail is vital in creating polished and visually appealing user interfaces and experiences.</li>
                                            <li>UX design interns should possess a creative mindset, being able to think outside the box and come up with innovative design solutions that address user needs and pain points.</li>
                                            <li>A good understanding of design thinking principles and user-centred design methodologies is essential.</li>

                                        </ol>
                                    </p>
                                    <p>
                                        <span><b>Duration of the Internship Programme:</b> 3 Months</span>
                                        <span><b>No. of Openings:</b> 2</span>
                                        <span><b>Compensation and Benefits</b><br /><br />
                                            Fixed Component: Rs 25,000 per month<br /><br />
                                            Variable Component: Rs 25,000 per month<br /><br />
                                            Certificate of Internship will be awarded to all interns who successfully complete their internship program. Full time employment offer might also be given to the top performers.<br /><br />
                                        </span>

                                    </p>

                                </TabPanel>
                                <TabPanel className='contentTabs'>
                                    <p>
                                        If you are interested in our internship program, kindly send an email to <a href="mailto:hr@edfora.com">hr@edfora.com</a>, including your Statement of Purpose (SOP) and resume. In your SOP, please elaborate on your interest in the program and explain why you believe you would be an excellent fit for the role.<br /><br />
                                        We look forward to receiving your application and learning more about your passion for joining our internship program at Edfora. Should you have any further questions or require additional information, please do not hesitate to reach out to us on <a href="mailto:hr@edfora.com">hr@edfora.com</a>. Thank you for considering Edfora for your internship experience. </p>


                                </TabPanel>
                                <TabPanel className='contentTabs'>
                                    <p>
                                        <ol>
                                            <li>Application Evaluation</li>
                                            <li>Psychometric and Subject Matter Test</li>
                                            <li>Interview</li>

                                        </ol>
                                    </p>


                                </TabPanel>

                            </Tabs>
                        </TabPanel>
                        {/* tab 3 start */}
                        <TabPanel className='subTabs'>
                            <Tabs>
                                <TabList>
                                    <Tab>Overview</Tab>
                                    <Tab>Roles and Responsibilities</Tab>
                                    <Tab>Eligibility</Tab>
                                    <Tab>Application Process</Tab>
                                    <Tab>Selection Process</Tab>
                                </TabList>
                                <TabPanel className='contentTabs'>
                                    <p>
                                        The Content Internship Program is designed to provide aspiring writers and content enthusiasts with a hands-on experience in the ever-evolving landscape of content marketing. This comprehensive program is the perfect platform for individuals looking to refine their writing skills, explore different content formats, and gain practical experience in content strategy and execution.<br /><br />
                                        Interns will get an opportunity to hone their writing acumen, develop a diverse portfolio of content, and equip themselves with the tools necessary to thrive in the dynamic landscape of content marketing. With mentorship from seasoned professionals and exposure to real-world projects, this internship offers a transformative journey for aspiring content creators, empowering them to make a lasting impact in the realm of digital communication.


                                    </p>

                                </TabPanel>
                                <TabPanel className='contentTabs'>
                                    <p>
                                        <ol type='1'>
                                            <li>Assist in creating compelling and engaging content across various platforms, such as blog posts, articles, social media posts, website copy, and email newsletters.</li>
                                            <li>Develop well-written and error-free content, ensuring it aligns with the brand's tone, style, and messaging.</li>
                                            <li>Conduct research to gather relevant information and data for content creation, ensuring accuracy and credibility.</li>
                                            <li>Collaborate with the content team to understand the content strategy, target audience, and goals for each piece of content.</li>
                                            <li>Learn and apply basic search engine optimization (SEO) principles to enhance content visibility and search rankings.</li>
                                            <li>Assist in promoting content through various channels, including social media platforms, to increase its reach and engagement.</li>
                                            <li>Review and proofread content to identify and correct any grammatical or typographical errors.</li>
                                            <li>Learn to analyze content performance metrics using tools like Google Analytics and provide insights for content improvement.</li>
                                            <li>Participate in brainstorming sessions to contribute innovative content ideas and explore new topics and formats.</li>
                                            <li>Work closely with the marketing and design teams to integrate content into broader marketing campaigns and initiatives.</li>

                                        </ol>

                                    </p>

                                </TabPanel>
                                <TabPanel className='contentTabs'>
                                    <p>
                                        <ol type='1'>
                                            <li>Class XII Pass students, students pursuing Undergraduate program or those who have recently graduated. Candidates pursuing or recent graduates in communications, journalism, marketing, English, or a related field will be preferred.</li>
                                            <li>Strong writing abilities with a flair for creative and engaging content are essential. Candidates should have a good command of grammar, punctuation, and storytelling techniques.</li>
                                            <li>Familiarity with various digital platforms, content management systems (CMS), and basic SEO principles is advantageous.</li>
                                            <li>A creative mindset that allows candidates to think outside the box and produce original and innovative content is highly desirable.</li>
                                            <li>The ability to conduct thorough research to gather information and insights for content creation is valuable.</li>
                                            <li>A keen eye for detail to ensure accuracy and consistency in the content produced.</li>
                                            <li>Effective time management skills to meet deadlines and handle multiple tasks efficiently.</li>
                                            <li>A willingness to learn and adapt to different writing styles, content formats, and subject matters.</li>


                                        </ol>
                                    </p>
                                    <p>
                                        <span><b>Duration of the Internship Programme:</b> 3 Months</span>
                                        <span><b>No. of Openings:</b> 2</span>
                                        <span><b>Compensation and Benefits</b><br /><br />
                                            Fixed Component: Rs 25,000 per month<br /><br />
                                            Variable Component: Rs 25,000 per month<br /><br />
                                            Certificate of Internship will be awarded to all interns who successfully complete their internship program. Full time employment offer might also be given to the top performers.<br /><br />
                                        </span>

                                    </p>

                                </TabPanel>
                                <TabPanel className='contentTabs'>
                                    <p>
                                        If you are interested in our internship program, kindly send an email to <a href="mailto:hr@edfora.com">hr@edfora.com</a>, including your Statement of Purpose (SOP) and resume. In your SOP, please elaborate on your interest in the program and explain why you believe you would be an excellent fit for the role.<br /><br />
                                        We look forward to receiving your application and learning more about your passion for joining our internship program at Edfora. Should you have any further questions or require additional information, please do not hesitate to reach out to us on <a href="mailto:hr@edfora.com">hr@edfora.com</a>. Thank you for considering Edfora for your internship experience. </p>


                                </TabPanel>
                                <TabPanel className='contentTabs'>
                                    <p>
                                        <ol>
                                            <li>Application Evaluation</li>
                                            <li>Psychometric and Subject Matter Test</li>
                                            <li>Interview</li>

                                        </ol>
                                    </p>


                                </TabPanel>

                            </Tabs>
                        </TabPanel>
                        {/* tab 4 start */}
                        <TabPanel className='subTabs'>
                            <Tabs>
                                <TabList>
                                    <Tab>Overview</Tab>
                                    <Tab>Roles and Responsibilities</Tab>
                                    <Tab>Eligibility</Tab>
                                    <Tab>Application Process</Tab>
                                    <Tab>Selection Process</Tab>
                                </TabList>
                                <TabPanel className='contentTabs'>
                                    <p>
                                    The Business Strategy Internship Program offers a unique opportunity for aspiring business professionals to gain hands-on experience in a dynamic and innovative setting. This comprehensive program is designed to equip interns with the skills and knowledge necessary to succeed in the field of business strategy.<br/><br/>
        <b>Key Benefits:</b> 
        <ol>
            <li><strong>Learn from Industry Experts:</strong> Work closely with experienced business professionals and gain insights into strategic decision-making.</li>
            <li><strong>Develop Practical Skills:</strong> Apply theoretical concepts to real-world business challenges and develop practical skills in market analysis, competitive intelligence, and strategic planning.</li>
            <li><strong>Build a Strong Portfolio:</strong> Contribute to strategic projects and build a portfolio of your work to showcase your skills and achievements.</li>
            <li><strong>Networking Opportunities:</strong> Connect with industry professionals and build valuable relationships that can benefit your career.</li>
        </ol>

<b>Program Highlights:</b>
<ol>
    <li>Strategic Planning: Assist in developing and implementing business strategies aligned with organizational goals.
    </li>
    <li>Market Analysis: Conduct market research and analysis to identify trends, opportunities, and competitive landscapes.</li>
    <li>Competitive Intelligence: Gather information on competitors and analyze their strategies.</li>
    <li>Financial Analysis: Assist in financial planning, budgeting, and performance analysis.</li>
    <li>Project Management: Contribute to strategic projects, from initiation to implementation.</li>
</ol>

<span>By participating in our Business Strategy Internship Program, you will gain invaluable experience and develop the skills necessary to succeed in the competitive world of business.</span>



                                    </p>

                                </TabPanel>
                                <TabPanel className='contentTabs'>
                                    <p>
                                        Collaborate with the business strategy team to support the development and implementation of effective business strategies.
                                        <br/><br/>
                                        <ol type='1'>
                                            <li>Assist in conducting market research and analysis to identify trends, opportunities, and competitive landscapes.
                                            </li>
                                            <li>Contribute to the development of strategic plans and roadmaps aligned with organizational goals.</li>
                                            <li>Analyze financial data and performance metrics to identify areas for improvement.</li>
                                            <li>Conduct competitive analysis to benchmark the organization against industry competitors.
                                            </li>
                                            <li>Develop and maintain strategic dashboards and reports to track progress and performance.
                                            </li>
                                            <li>Collaborate with cross-functional teams to ensure alignment of business strategies with operational plans and initiatives.
                                            </li>
                                            <li>Assist in the development of business cases and proposals for new products, services, or initiatives.</li>
                                            <li>Conduct strategic planning workshops and facilitate discussions with stakeholders.
                                            </li>
                                            <li>Support the implementation of strategic initiatives and monitor progress towards achieving goals.
                                            </li>
                                            <li>Stay up-to-date with industry trends and best practices in business strategy.</li>

                                        </ol>

                                    </p>

                                </TabPanel>
                                <TabPanel className='contentTabs'>
                                    <p>
                                        <ul>
                                            
                                            <li>Strong analytical and problem-solving skills
                                            </li>
                                            <li>Excellent communication and interpersonal skills
                                            </li>
                                            <li>Proficiency in Microsoft Office Suite (Excel, PowerPoint, Word)</li>
                                            <li>Interest in business strategy and management
                                            </li>
                                            <li>Pursuing a degree in business, economics, or a related field
                                            </li>


                                        </ul>
                                    </p>
                                   <p>International candidates can also apply for this role. Their remuneration will be commensurate with their qualification and expertise and at par with the market.</p>

                                </TabPanel>
                                <TabPanel className='contentTabs'>
                                    <p>
                                        If you are interested in our internship program, kindly send an email to <a href="mailto:hr@edfora.com">hr@edfora.com</a>, including your Statement of Purpose (SOP) and resume. In your SOP, please elaborate on your interest in the program and explain why you believe you would be an excellent fit for the role.<br /><br />
                                        We look forward to receiving your application and learning more about your passion for joining our internship program at Edfora. Should you have any further questions or require additional information, please do not hesitate to reach out to us on <a href="mailto:hr@edfora.com">hr@edfora.com</a>. Thank you for considering Edfora for your internship experience. </p>


                                </TabPanel>
                                <TabPanel className='contentTabs'>
                                    <p>
                                        <ol>
                                            <li>Application Evaluation</li>
                                            <li>Psychometric and Subject Matter Test</li>
                                            <li>Interview</li>

                                        </ol>
                                    </p>


                                </TabPanel>

                            </Tabs>
                        </TabPanel>
                    </Tabs>
                </div>






            </Container>




        </div>
    )
}

export default internShip;